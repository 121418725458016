<template>
  <div>
    <DataTable
      v-model:filters="filters"
      v-model:expandedRows="expandedRows"
      :value="items"
      :loading="loading"
      :rows="10"
      lazy
      row-hover
      paginator
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-sm"
      dataKey="id"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      scrollable
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column style="min-width: 3rem; flex: initial" header="" expander />
      <Column field="kode" header="No" style="min-width: 200px" sortable>
        <template #body="{ data }">
          {{ data.km_no }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        filter-field="kas_between"
        field="km_at"
        header="Tanggal"
        style="min-width: 200px"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.km_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            ref="calendarFilter"
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="kas_id"
        header="Kas/Bank"
        style="min-width: 300px"
        sortable
      >
        <template #body="{ data }">
          {{ data.kas.nama }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="accounts"
            optionValue="id"
            optionLabel="nama"
            class="p-column-filter"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column field="keterangan" header="Keterangan" style="min-width: 300px" />
      <Column
        field="jumlah"
        header="Jumlah"
        dataType="numeric"
        style="
          min-width: 90px;
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
        "
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah) }}
        </template>
      </Column>
      <Column
        style="
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;
          min-width: 200px;
        "
      >
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-print"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            v-tooltip.top="'Cetak Bukti Penerimaan'"
            @click="$emit('print', data)"
          />
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
          <Button
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          />
        </template>
      </Column>
      <template #expansion="slotProps">
        <div class="card">
          <DataTable
            v-model:expandedRows="expandedRows"
            :value="slotProps.data.detail"
            :scrollable="true"
            class="p-datatable-sm"
            showGridlines
            scrollHeight="400px"
          >
            <Column
              field="account.nama"
              header="Perkiraan"
              style="min-width: 300px"
            />
            <Column
              field="keterangan"
              header="Keterangan"
              style="min-width: 300px"
            />
            <Column
              field="reff_no"
              header="No. Referensi"
              style="min-width: 300px"
            />
            <Column
              field="nilai"
              header="Nilai"
              style="flex-grow: 1; min-width: 4rem; justify-content: flex-end"
            >
              <template #body="{ data }">
                {{ formatCurrency(data.nilai) }}
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers'
import CalendarFilter from '@/components/CalendarFilter'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    accounts: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: { CalendarFilter },
  data() {
    return {
      expandedRows: [],
      options: this.gridOptions,
      filters: {
        kode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kas_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        kas_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  watch: {
    'options.filters'(value) {
      if (!value.kas_between.value) {
        this.$refs.calendarFilter.clearValueOnly()
      }
    },
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 2, maxDigit = 2) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
